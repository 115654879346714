import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import title_image from '../../../images/titles/process_capability_and_assessment.jpg'
import person from '../../../images/person.png'

const pageTitle = "Process and Capability Assessment";
const pageSLug = "process-and-capability-assessment";
class ProcessCapability extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Our experts assess your process effectiveness and prioritize 
            your processes to align with your business strategy.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>Process improvement initiatives should be prioritized based on their 
                        value to both your business and organizational readiness.</h3>
                    <p>Your processes, organizational structure, personnel, and technology solutions collectively 
                        define how work is done, and value is created. Major organizations continually review the 
                        effectiveness of core operational processes to identify issues and improve performance.</p>
                    <span className="break"></span>
                    <h4>Process Assessment Service Overview</h4>
                    <p>Process and Capability Assessment service is a way to assess process effectiveness and prioritize 
                        processes to align with your business strategy. We want to work together to identify and measure 
                        process performance and maturity levels across all operational areas.
                        Our experts will identify root cause issues, key opportunities, and recommendations for each 
                        operational area by using performance, process analysis, baselining, and benchmarking.
                        Our experts will find the quickest path to creating value by targeting high value-adding 
                        opportunities across processes. With Value Targeting and Process Assessment, they can find the 
                        fastest way to add business value.</p>
                    <p>Not sure where to start? Our experts can help.</p>
                   <Link to="/contact-us" className="button green">Let's Talk</Link>
                
                    <span className="break"></span>
                    <h4>Process Assessment/Hierarchy</h4>
                    <p>Together, we can define core processes and identify their position in your business, 
                        from high-level complex processes to low-level sub-processes.</p>
                    <p>By defining business processes in a hierarchy, we can identify improvement opportunities and advance process maturity.</p>
                    <span className="break"></span>
                    <h4>Process Documentation</h4>
                    <p>Our documentation tools and techniques create a detailed understanding of end-to-end processes and inter-related 
                        variables they include:</p>

                    <ul>
                        <li>The basis to manage and improve processes</li>
                        <li>Employee orientation to core processes</li>
                        <li>A consistent view of operations</li>
                        <li>Consistent and standardized procedures</li>
                    </ul> 
                    <span className="break"></span>
                    <h4>Process Maturity/Opportunity Assessment</h4>  
                    <p>Process maturity contains several criteria that are used to determine key themes, opportunities, and risks in processes. 
                        Our standards include:</p> 
                    <ul>
                        <li>Process Awareness and Knowledge</li>
                        <li>Process Alignment and Ownership</li>
                        <li>Process Effectiveness</li>
                        <li>Performance Metric Alignment</li>
                        <li>Technology Proficiency</li>
                        <li>Organizational Skills</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Capability and Readiness Assessment</h4>
                    <p>It's critical to understand your organization's ability to sustain and build upon a process excellence program. 
                        Our experts can provide a readiness assessment program for each major division and department.</p>
                    <p>We can help you evaluate areas that are critical to support process excellence; these include:</p>
                    <ul>
                        <li>Training Effectiveness</li>
                        <li>Readiness to Change</li>
                        <li>Technology Platforms</li>
                        <li>PEX program sponsorships</li>
                        <li>PEX Methods, Tools and Analytics</li>
                        <li>PEX Resources/Skills</li>
                        <li>Governance/PMO/COE Structures</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Opportunity Prioritization</h4>
                    <p>Process improvement opportunities should be prioritized based on value impact, levels of effort, and 
                        levels of risk. This allows for quantitative and qualitative analysis that will enable you to select 
                        the right opportunities at the right time and maximize your return on investment.</p>
                        <span className="break"></span>
                    <h4>Roadmap and Business Case Development</h4>
                    <p>Developing a strong business case is critical for implementing a successful process excellence program. 
                        It's crucial to communicate the strategy and the path to get your organization to where you want it to be. 
                        This facilitates the conversation around funding but also assists with the alignment of stakeholders.</p>
                        <span className="break"></span>
                    <h4>Process Assessment Capabilities</h4>
                    <p>Nuerex's process Assessment services are customized and personalized to meet your specific business needs. 
                        We ensure that the maximum amount of value is delivered in the shortest amount of time.</p>
                    <ul>
                        <li>Process Documentation</li>
                        <li>Opportunity Prioritization</li>
                        <li>Roadmap and Business Case Development</li>
                        <li>Capability and Readiness Assessment</li>
                        <li>Process Definitions</li>
                        <li>Opportunity assessment</li>
                    </ul>
                    <span className="break"></span>
                        <div className="contact-box">
                        <h3>Want to improve and align your processes? Our experts can get you there.</h3>
                        <span className="break"></span>
                        <div className="button-area">
                            <Link to="/contact-us" className="button default">Talk with an Expert</Link>
                        </div>
                    </div>
                </div>
               {/* <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default ProcessCapability
